import React from 'react';
import { NavLink } from 'react-router-dom';
import Logo from '../images/PHOENIXSOLUTION-1.png';
import Visa from '../images/visa.png.webp';
import MasterCard from '../images/master.png.webp'; 
import PayPal from '../images/paypal.png.webp'; 
import Amex from '../images/amex.png.webp'; 
import '../styles/Footer.scss';
import '@fortawesome/fontawesome-free/css/all.min.css'; // Import FontAwesome

const Footer = ({ isContactUsVisible }) => {
  //console.log("Footer_isContactUsVisible = ",isContactUsVisible);
  return (
    <div className="footer">
      <div className="footer-mx" style={{ marginTop: isContactUsVisible ? '7rem' : '0rem' }}>

        <div className="footer-content">
          <div className="footer-logo">
            <img src={Logo} alt="Company Logo" />
          </div>
          <div className="quick-links">
            <h3>Quick Links</h3>
            <ul>
              <li><NavLink to="/">Home</NavLink></li>
              <li><NavLink to="/store">Store</NavLink></li>
              <li><NavLink to="/shipping-returns">Shipping & Returns</NavLink></li>
              <li><NavLink to="/terms-conditions">Terms & Conditions</NavLink></li>
              <li><NavLink to="/privacy-statement">Privacy Statement</NavLink></li>
              <li><NavLink to="/contactus">Contact Us</NavLink></li>
            </ul>
          </div>

          <div className="products">
            <h3>Products</h3>
            <ul>
              <li><NavLink to="/products/silicon-wafers">Silicon Wafers</NavLink></li>
              <li><NavLink to="/products/float-zone-wafers">Float Zone Silicon Wafers</NavLink></li>
              <li><NavLink to="/products/soi-wafers">SOI Wafers</NavLink></li>
              <li><NavLink to="/products/thermal-oxide-wafers">Thermal Oxide Wafers</NavLink></li>
              <li><NavLink to="/products/nitride-wafers">Nitride Wafers</NavLink></li>
              <li><NavLink to="/products/fused-silica-wafers">Fused Silica Wafers</NavLink></li>
              <li><NavLink to="/products/borofloat-glass-wafers">Borofloat Glass Wafers</NavLink></li>
              <li><NavLink to="/products/other-materials">Other Materials</NavLink></li>
            </ul>
          </div>
          <div className="contact-info">
          <div>
            <h3>Contact Us</h3>
            <p><i className="fas fa-map-marker-alt"></i> 412 Martin Ave, Santa Clara, CA 95050</p>
            <p><i className="fas fa-envelope"></i> sales@yourdomain.com</p>
            <p><i className="fas fa-phone-alt"></i> (408) 622-9129</p>
            <p><i className="fas fa-clock"></i> Monday – Friday 9:00 a.m. to 6:00 p.m.</p>
          </div>
          </div>
        </div>
        <div className="footer-bottom">
          <p>© Copyright Your Company 2024</p>
          <div className="payment-icons">
            <img src={Visa} alt="Visa" />
            <img src={MasterCard} alt="MasterCard" />
            <img src={PayPal} alt="PayPal" />
            <img src={Amex} alt="Amex" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
