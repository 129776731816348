// QuoteRequestFormSection.jsx

import React from 'react';
import '../../styles/QuoteRequestForm/QuoteRequestFormSection.scss';

const QuoteRequestFormSection = () => {
  return (
    <div id="quote-request-form-section" className="quote-request-form-section">
      <h1 className="title">Quote Request Form</h1>
      <div className="quote-request-form-sections">
        <div className="personal-information">
          <h2>Personal Information</h2>
          <div className="form-group">
            <input type="text" placeholder="First Name *" />
            <input type="text" placeholder="Last Name *" />
            <input type="text" placeholder="Company Name" />
            <input type="email" placeholder="Email Id *" />
            <input type="text" placeholder="Phone number" />
            </div>
        </div>

        <div className="product-information">
          <h2>Product Information</h2>

          <div className="form-group">
            <label className="sub-title">Material</label>
            <div className="options material">
              <label><input type="radio" name="material" /> Silicon Wafer</label>
              <label><input type="radio" name="material" /> Float Zone Wafer</label>
              <label><input type="radio" name="material" /> SOI Wafer</label>
              <label><input type="radio" name="material" /> Fused Silica Wafer</label>
              <label><input type="radio" name="material" /> Borofloat Glass Wafer</label>
              <label><input type="radio" name="material" /> Crystal Quartz Wafer</label>
              <label className='other'>
                <input type="radio" name="material" /> 
                Other
                <input type="text" placeholder="Other" />
              </label>
              
            </div>
          </div>

          <div className="form-group">
            <label className="sub-title">Diameter</label>
            <div className="options diameter">
              <label><input type="radio" name="diameter" /> 2” (50.8mm)</label>
              <label><input type="radio" name="diameter" /> 3” (76.2mm)</label>
              <label><input type="radio" name="diameter" /> 4” (100.0mm)</label>
              <label><input type="radio" name="diameter" /> 5” (125.0mm)</label>
              <label><input type="radio" name="diameter" /> 6” (150.0mm)</label>
              <label><input type="radio" name="diameter" /> 8” (200.0mm)</label>
              <label><input type="radio" name="diameter" /> 12” (300.0mm)</label>
              <label className='other'>
                <input type="radio" name="diameter" /> 
                Other
                <input type="text" placeholder="Other" />  
              </label>
            </div>
          </div>

          <div className="form-group">
            <label className="sub-title">Type</label>
            <div className="options type">
              <label><input type="radio" name="type" /> P Type</label>
              <label><input type="radio" name="type" /> N Type</label>
              <label><input type="radio" name="type" /> Intrinsic</label>
              <label className='other'>
                <input type="radio" name="type" /> 
                Other
                <input type="text" placeholder="Other" />
              </label>
            </div>
          </div>

          <div className="form-group">
            <label className="sub-title">Dopant</label>
            <div className="options dopant">
              <label><input type="radio" name="dopant" /> B</label>
              <label><input type="radio" name="dopant" /> Ph</label>
              <label><input type="radio" name="dopant" /> As</label>
              <label><input type="radio" name="dopant" /> Sb</label>
              <label><input type="radio" name="dopant" /> Undoped</label>
              <label className='other'>
                <input type="radio" name="dopant" /> 
                Other
                <input type="text" placeholder="Other" />
              </label>
            </div>
          </div>

          <div className="form-group">
            <label className="sub-title">Orientation</label>
            <div className="options orientation">
              <label><input type="radio" name="orientation" /> (1-0-0)</label>
              <label><input type="radio" name="orientation" /> (1-1-1)</label>
              <label><input type="radio" name="orientation" /> (1-1-0)</label>
              <label className='other'>
                <input type="radio" name="orientation" /> 
                Other
                <input type="text" placeholder="Other" />
              </label>
            </div>
          </div>

          <div className="form-group">
            <label className="sub-title">Flat/Notch</label>
            <div className="options flat-notch">
              <label><input type="radio" name="flat_notch" /> 1 Semi Flat</label>
              <label><input type="radio" name="flat_notch" /> 2 Semi Flat</label>
              <label><input type="radio" name="flat_notch" /> Jeida Flat</label>
              <label><input type="radio" name="flat_notch" /> Notched</label>
              <label className='other'>
                <input type="radio" name="flat_notch" /> 
                Other
                <input type="text" placeholder="Other" />
              </label>
            </div>
          </div>

          <div className="form-group">
            <label className="sub-title">Resistivity Range (Ohm-cm)</label>
            <input type="text" placeholder="Standard or Custom (Please Specify)" />
          </div>

          <div className="form-group">
            <label className="sub-title">Thickness Range (Micrometer)</label>
            <input type="text" placeholder="Standard or Custom (Please Specify)" />
          </div>

          <div className="form-group">
            <label className="sub-title">Finish</label>
            <div className="options finish">
              <label><input type="radio" name="finish" /> Single Side Polished (SSP)</label>
              <label><input type="radio" name="finish" /> Double Side Polished (DSP)</label>
              <label><input type="radio" name="finish" /> Etched/Etched (E/E)</label>
              <label className='other'>
                <input type="radio" name="finish" /> 
                Other
                <input type="text" placeholder="Other" />
              </label>
            </div>
          </div>

          <div className="form-group">
            <label className="sub-title">Film Thickness (Å)</label>
            <input type="text" placeholder="Film Thickness (Å)" />
          </div>

          <div className="form-group">
            <label className="sub-title">Film (Need film on wafers?)</label>
            <div className="options film">
              <label><input type="radio" name="film" /> Dry Thermal Oxide</label>
              <label><input type="radio" name="film" /> Wet Thermal Oxide</label>
              <label><input type="radio" name="film" /> Stoichiometric LPCVD Nitride</label>
              <label><input type="radio" name="film" /> Low Stress LPCVD Nitride</label>
              <label><input type="radio" name="film" /> Super Low Stress LPCVD Nitride</label>
              <label><input type="radio" name="film" /> PECVD Nitride</label>
              <label className='other'>
                <input type="radio" name="film" /> 
                Other
                <input type="text" placeholder="Other" />
              </label>
            </div>
          </div>

          <div className="form-group">
            <label className="sub-title">Additional Requirements</label>
            <textarea placeholder="Standard or custom BOW, WARP, TTV, PARTICLE (Please Specify)" rows="4"></textarea>
          </div>

          <div className="form-group">
            <label className="sub-title">Quantity (pcs)</label>
            <input type="number" placeholder="Quantity" />
          </div>

          <div className="form-group">
            <label className="sub-title">Spec Sheet (if any)</label>
            <input type="file" />
          </div>
          
        </div>
        <div className="additional-information">
          <h2>Additional Information</h2>

          <div className="form-group">
            <label className="sub-title">Comments</label>
            <textarea placeholder="Comments Here" rows="4"></textarea>
          </div>
        </div>
        <div className="button">
          <button type="submit" className="submit-button">Submit</button>
        </div>
      </div>
    </div>
  );
};

export default QuoteRequestFormSection;
