//Homeabout.jsx
import React from 'react';
import { Link } from 'react-router-dom';
import '../../styles/Home/Homeabout.scss'; // Make sure to import the related SCSS file
import Logo from '../../images/PHOENIXSOLUTION-1.png';

const Homeabout = () => {
  return (
    <section className="who-we-are-sec cmn-gap">
      <div className="container">
        <div className="who-we-are-content">
          <div className="who-we-are-row row">
            <div className="who-we-are-col col-lg-7">
              <div className="who-we-are-img-area">
                <img
                  width="641"
                  height="628"
                  src={Logo}
                  alt="who-we-are-image"
                />
                <div className="vertical-txt">Who We Are</div>
              </div>
            </div>
            <div className="who-we-are-col col-lg-5">
              <div className="who-we-are-text-area">
                <h2>Who We Are</h2>
                <p>
                  Silicon Phoenixsolution is a leading semiconductor silicon wafer manufacturer headquartered in the heart of the Silicon Valley, California. Founded in 2016, we have rapidly grown to become a premier supplier of high-quality silicon wafers to customers globally.
                </p>
                <h3><strong>Why Choose Silicon Phoenixsolution?</strong></h3>
                <ul>
                  <li><strong>Expertise</strong>: With a team of experienced professionals and state-of-the-art manufacturing facilities, we deliver consistently high-quality wafers.</li>
                  <li><strong>Wide Range of Products</strong>: From Czochralski (CZ) and Float Zone (FZ) silicon wafers to SOI and glass wafers, we offer a comprehensive selection to meet your specific needs.</li>
                  <li><strong>Customization</strong>: We specialize in manufacturing custom wafers to your exact specifications, ensuring your project’s success.</li>
                  <li><strong>Fast Turnaround</strong>: Our streamlined processes and in-stock inventory enable us to provide quick delivery, keeping your projects on schedule.</li>
                  <li><strong>Global Reach</strong>: We ship worldwide from our US warehouse, serving customers in over 50 countries.</li>
                </ul>
                <div className="btn-center">
                  <Link to="/about">
                    <div className="btn-center">
                      <button>
                        <span>About Us</span>
                        {/* <i>
                          <img src={ArrowWhite} alt="btn-arrow-white" />
                        </i> */}
                      </button>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Homeabout;
