// WorkingWaperSec.jsx
import React from 'react';
import { Link } from 'react-router-dom';
import '../../styles/Home/WorkingWaperSec.scss';
import WorkFor from '../../images/BuildSiliconWafer/work-for.png.webp';

const WorkingWaferSec = () => {
  return (
    <section className="working-waper-sec">
      <div className="container">
        <div className="working-waper-cont">
        <div className="working-waper-row row">
          <div className="col-md-6 working-waper-col">
            <div className="working-waper-img">
              <img
                src={WorkFor}
                alt="working-at-Silicon Phoenixsolution"
              />
            </div>
          </div>

          <div className="col-md-6 working-waper-col">
            <div className="working-waper-text">
              <h2>Working at Silicon Phoenixsolution</h2>
              <p>
                Our teams operate with a non-conventional inter-disciplinary collaboration. We have a passion for continuously improving our products and customer experience.
              </p>
              <Link to="/quoterequest">
                <div className="btn-center">
                <button>
                    <span>Request a Quote</span>
                    {/* <i>
                    <img src={ArrowWhite} alt="btn-arrow-white" />
                    </i> */}
                </button>
                </div>
            </Link>
            </div>
          </div>
        </div>
        </div>
      </div>
    </section>
  );
};

export default WorkingWaferSec;
