import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay } from 'swiper/modules';
import { Link } from 'react-router-dom';
import dot_img from '../../images/Style/dot-img.svg';
import what_vec_pattern from '../../images/Style/what-vec-pattern.png.webp';
import vector_pattarn from '../../images/Style/vector-pattarn.png.webp';
import circle_down from '../../images/Style/circle-down.png.webp';
import circle_up from '../../images/Style/circle-up.svg';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import '../../styles/Home/WhatWeMake.scss';

const WhatWeMake = () => {
    const imageServerURL = process.env.REACT_APP_IMAGE_SERVER_URL;
    const [products, setProducts] = useState([]);

    useEffect(() => {
        return () => {
            setProducts([]);
        };
    }, []);    

    useEffect(() => {
        const fetchProducts = async () => {
            const url = 'https://nodejs.phoenixsolution.us/api/products'; // API URL

            try {
                const response = await fetch(url);
                const result = await response.json();
                //console.log("-----------------------------", result); // Debug: Print fetched JSON data
                setProducts(result);
            } catch (error) {
                //console.error('Error fetching products:', error);
            }
        };

        fetchProducts();
    }, []);

    return (
        <section className="what-we-make cmn-gap">
            {/* Decorative elements */}
            <i className="dot-img">
                <img
                    width="125"
                    height="168"
                    src={dot_img}
                    alt="dot pattern"
                />
            </i>
            <i className="top-pattern">
                <img
                    width="253"
                    height="218"
                    src={what_vec_pattern}
                    alt="vector pattern"
                />
            </i>
            <i className="top-vector">
                <img
                    width="377"
                    height="572"
                    src={vector_pattarn}
                    alt="vector pattern"
                />
            </i>
            <div className="what-we-make-container">
                <div className="center-cont">
                    <i className="top-circle">
                        <img
                            width="331"
                            height="259"
                            src={circle_down}
                            alt="circle img"
                        />
                    </i>
                    <i className="bottom-circle">
                        <img
                            width="216"
                            height="164"
                            src={circle_up}
                            alt="circle img"
                        />
                    </i>
                    <h2>What we make</h2>
                    <p>
                    Silicon Phoenixsolution offers a comprehensive Czochralski CZ silicon wafers, Float Zone FZ silicon wafers, SOI wafers, and Glass wafers ranging from non-polished to ultra-thin wafers.
                    </p>
                </div>

                <div className="cmn-slider-wrap">
                {products.length > 0 && (
                    <Swiper
                        spaceBetween={0}
                        slidesPerView={4} // Default view for screens wider than 1200px
                        navigation={true}
                        autoplay={{ delay: 3000 }}
                        modules={[Navigation, Autoplay]}
                        loop={true}
                        breakpoints={{
                            1200: {
                                slidesPerView: 4, // 4 slides for screens 1200px and wider
                            },
                            990: {
                                slidesPerView: 3, // 3 slides for screens 991px to 1199px
                            },
                            885: {
                                slidesPerView: 2, // 2 slides for screens 885px to 990px
                            },
                            454: {
                                slidesPerView: 1, // 1 slide for screens 454px to 884px
                            },
                            0: {
                                slidesPerView: 1, // 1 slide for screens smaller than 454px
                            },
                        }}


                    >
                    {products.map((product) => (
                        <SwiperSlide key={product.id}>
                            <div className="cmn-card">
                                <div className="cmn-card-pic">
                                    <img src={`${imageServerURL}${product.image}`} alt={product.name} />
                                </div>
                                <h3>{product.name}</h3>
                                <p>{product.description}</p>
                                <a href="#" className="lean-more">
                                    Learn more <i className="fa-solid fa-arrow-up"></i>
                                </a>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            )}
            </div>


            <Link to="/quoterequest">
          <div className="btn-center">
            <button>
              <span>Request a Quote</span>
              {/* <i>
                <img src={ArrowWhite} alt="btn-arrow-white" />
              </i> */}
            </button>
          </div>
        </Link>
            </div>
        </section>
    );
};

export default WhatWeMake;
