// PoductsSection.jsx

import React, { useState } from 'react';
//import '../../styles/ContactUsForm/ContactSection.scss';

const ContactSection = () => {

  return (
    <>
        <section id="products-section" className="products-section">
            <h2>Products</h2>
        </section>
    </>
  );
}

export default ContactSection;