//Topbar.jsx
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import Logo from '../images/PHOENIXSOLUTION-1.png'; 
import '../styles/Topbar.scss';

const Topbar = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  return (
    <div className="topbar">
      <div className="announcement">
        Free shipping on all U.S. orders
      </div>
      <div className="main-menu">
        <NavLink to="/" className={({ isActive }) => isActive ? 'active' : ''}>
          <img src={Logo} alt="Company Logo" className="logo" />
        </NavLink>
        <button className="nav-toggle" onClick={toggleNav}>
          ☰ {/* Unicode for hamburger icon */}
        </button>
        <nav className={`nav-links ${isNavOpen ? 'open' : ''}`}>
          <button className="close-btn" onClick={toggleNav}>✖</button>
          <NavLink to="/" className={({ isActive }) => isActive ? 'active' : ''}>Home</NavLink>
          <NavLink to="/about" className={({ isActive }) => isActive ? 'active' : ''}>About</NavLink>
          <NavLink to="/products" className={({ isActive }) => isActive ? 'active' : ''}>Products</NavLink>
          <NavLink to="/store" className={({ isActive }) => isActive ? 'active' : ''}>Store</NavLink>
          <NavLink to="/quoterequest" className={({ isActive }) => isActive ? 'active' : ''}>Request a Quote</NavLink>
          <NavLink to="/contactus" className={({ isActive }) => isActive ? 'active' : ''}>Contact Us</NavLink>
        </nav>
        {isNavOpen && <div className="overlay" onClick={toggleNav}></div>}
      </div>
    </div>
  );
};

export default Topbar;
