// Products.jsx

import React from 'react';
import ProductsComenTopBanner from '../components-templates/Products/ProductsComenTopBanner';
import PoductsSection from '../components-templates/Products/PoductsSection';
//import '../styles/AboutUs/AboutUs.scss';

const Products = () => {
  return (
    <>
      <div className="products">
        <ProductsComenTopBanner />
        <PoductsSection />
      </div>
    </>
  );
};

export default Products;