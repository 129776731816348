// import logo from './logo.svg';
// import './App.css';

// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }

// export default App;

// http-server D:\Phoenix_solution\Image\Product_Image

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Layout from './components/Layout';
import Home from './pages/Home';
import About from './pages/AboutUs';
import QuoteRequestForm from './pages/QuoteRequestForm';
import ContactUsForm from './pages/ContactUsForm';
import Products from './pages/Products';
import Store from './pages/Store';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Layout><Home /></Layout>} />
        <Route path="/about" element={<Layout><About /></Layout>} />
        <Route path="/quoterequest" element={<Layout><QuoteRequestForm /></Layout>} />
        <Route path="/contactus" element={<Layout><ContactUsForm /></Layout>} />
        <Route path="/products" element={<Layout><Products /></Layout>} />
        <Route path="/store" element={<Layout><Store /></Layout>} />
      </Routes>
    </Router>
  );
}

export default App;
